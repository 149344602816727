
html,
body,
#root,
canvas,
.wrap {
	width: 100%;
	height: 100%;
	padding: 0;
  margin: 0;
}

.wrap {
  display:flex;
}


@media (orientation: landscape) {
  .wrap {
    flex-direction: row;
  }
}

@media (orientation: portrait) {
  .wrap {
    flex-direction: column;
  }
}

.viewport{
    flex: 80%;
    height: 100%;
    overflow: hidden;
}

.parameters{
    flex: 20%;
    height: 100%;
    overflow: auto;
}